var routesJson = require('./routesNew.json');
var stopsJson = require('./stops.json');

(function () {
  const routes = [];
  const stops = [];
  let isTablet = false;
  let prevClickedIndex = NaN;
  const lines = [];
  let nearestTime = '';
  const windowSize = getWindowSize();

  const vm = new Vue({

    el: '#app',
    data: {
      routes,
      stops,
      markers: [],
      map: {},
      isActive: false,
      routeName: '',
      isTablet,
      showMenu: windowSize,
    },
    mounted() {
      this.initMap();
      for (let i = 0; i < routesJson.length; i++) {
        routes.push({
          Number: routesJson[i].numberId,
          Name: routesJson[i].routName,
          FullName: routesJson[i].routFullName,
          Id: i,
          Stops: routesJson[i].stopsId['Dep'],
          IsOpen: false,
          DepOrder: 'Dep',
        });
      }

      for (let i = 0; i < stopsJson.length; i++) {
        stops.push({
          Id: stopsJson[i].id,
          Name: stopsJson[i].name,
          Lng: stopsJson[i].lng,
          Lat: stopsJson[i].lat,
          Dep: stopsJson[i].departures,
          DepBack: stopsJson[i].departuresBack,
          ShowDepartures: false,
        });
      }
    },
    computed: {
      getStop() {
        return (id) => this.stops.find((stop) => stop.Id == id);
      },
      getDepartures() {
        return (id, routeNum, departure) =>  {
          let departuresArray = this.stops.find((stop) => stop.Id === id.toString())[departure][routeNum];
          nearestTime = this.searchClosestHour(departuresArray);
          return departuresArray;
        }
      },
      getNearestTime() {
        return () => nearestTime;
      }
    },
    methods: {
      initMap() {
        const mapOptions = {
          zoom: 13,
          center: new google.maps.LatLng(49.96935221042123, 20.430403492057415),
        };
        const map = new google.maps.Map(document.getElementById('map'), mapOptions);

        this.map = map;
      },
      initRoute(stops, index) {
        if (!isNaN(prevClickedIndex) && prevClickedIndex !== index) {
          this.stops.forEach((x) => x.ShowDepartures = false);
          routes[prevClickedIndex].IsOpen = false;
        }

        if (!routes[index].IsOpen) {
          routes[index].IsOpen = true;
          prevClickedIndex = index;
          this.drawMap(stops, index);
        }
      },
      drawMap(stops, index){
        this.cleanMap();
        this.isTablet ? this.showMenu = false : this.showMenu;

        var stops = stops.toString()
          .split(',');
        const stopsLength = stops.length;
        let i;
        const markers = [];
        let marker; // init google map marker
        let infoWindow;
        const { map } = this;
        let currentMinLng;
        let currentMaxLng;
        let currentMinLat;
        let currentMaxLat;

        this.routeName = `<span class='bus-num'>${this.routes[index].Number}</span> ${this.routes[index].Name} : ${this.routes[index].FullName}`;

        for (i = 0; i < stopsLength; i++) {
          const pos = this.stops.findIndex((x) => x.Id === stops[i]);
          markers.push(this.stops[pos]);
        }

        currentMinLng = currentMaxLng = +markers[0].Lng;
        currentMinLat = currentMaxLat = +markers[0].Lat;

        for (i = 0; i < markers.length; i++) {
          marker = new google.maps.Marker({
            position: {
              lat: +markers[i].Lat,
              lng: +markers[i].Lng,
            },
            title: markers[i].Name,
            map: this.map,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 5,
              strokeColor: '#F00',
              strokeWeight: 5,
              fillColor: 'white',
              fillOpacity: 1,
            },
            store_id: markers[i].Id,
          });

          infoWindow = new google.maps.InfoWindow({
            content: `<div><p>${marker.title}</p></div>`,
          });

          var that = this;
          google.maps.event.addListener(marker, 'click', (function (marker) {
            return function () {
              // let routesFromStop = that.routes.filter((x) => x.Stops.includes(parseInt(marker.get('store_id')))).map((route) => route.Number).join(', ');

              let myDepOrder = that.routes[index].DepOrder;
              let routesFromStop = routesJson.filter((x) => x.stopsId[myDepOrder].includes(parseInt(marker.get('store_id')))).map((route) => route.numberId).join(', ');

              infoWindow.setContent(`
                            <div><span>${marker.title}</span></div>
                            <div>Linie: <span style="font-weight: bold">${routesFromStop}</span></div>
                            `);
              infoWindow.open(map, marker);
              that.getStop(marker.get('store_id')).ShowDepartures = !that.getStop(marker.get('store_id')).ShowDepartures;
            };
          }(marker)));

          // находим крайние точки маршрута, что бы в дальнейшем отцентрировать карту для выбранного маршрут
          currentMinLat = marker.position.lat() < currentMinLat ? marker.position.lat() : currentMinLat;
          currentMinLng = marker.position.lng() < currentMinLng ? marker.position.lng() : currentMinLng;
          currentMaxLat = marker.position.lat() > currentMaxLat ? marker.position.lat() : currentMaxLat;
          currentMaxLng = marker.position.lng() > currentMaxLng ? marker.position.lng() : currentMaxLng;

          this.markers.push(marker);
        }
        // batches
        const batches = [];
        const itemsPerBatch = 10;
        let itemsCounter = 0;
        let wayptsExist = markers.length > 0;

        while (wayptsExist) {
          const subBatch = [];
          let subitemsCounter = 0;

          for (let j = itemsCounter; j < markers.length; j++) {
            subitemsCounter++;
            subBatch.push({
              location: new google.maps.LatLng(parseFloat(markers[j].Lat), parseFloat(markers[j].Lng)),
              stopover: true,
            });

            if (subitemsCounter == itemsPerBatch) {
              break;
            }
          }

          itemsCounter += subitemsCounter;
          batches.push(subBatch);
          wayptsExist = itemsCounter < markers.length;
          itemsCounter--;
        }

        for (let k = 0; k < batches.length; k++) {
          const lastIndex = batches[k].length - 1;
          const start = batches[k][0].location;
          const end = batches[k][lastIndex].location;

          // trim first and last entry from array
          let waypts = [];
          waypts = batches[k];
          waypts.splice(0, 1);
          waypts.splice(waypts.length - 1, 1);

          const request = {
            origin: start,
            destination: end,
            waypoints: waypts,
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
          };

          new google.maps.DirectionsService().route(request, (result, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
              const path = new google.maps.MVCArray();
              const poly = new google.maps.Polyline({
                map,
                strokeColor: '#f3443c',
                strokeWeight: 5,
              });
              lines.push(poly);
              for (let i = 0, len = result.routes[0].overview_path.length; i < len; i++) {
                path.push(result.routes[0].overview_path[i]);
              }
              poly.setPath(path);
            } else if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
              setTimeout(3000);
            }
          });
        }
        this.map.setCenter(new google.maps.LatLng(
          ((currentMaxLat+ currentMinLat) / 2.0),
          ((currentMaxLng + currentMinLng) / 2.0)
        ));
        this.map.fitBounds(new google.maps.LatLngBounds(
          //bottom left
          new google.maps.LatLng(currentMinLat, currentMinLng),
          //top right
          new google.maps.LatLng(currentMaxLat, currentMaxLng)
        ));
      },
      cleanMap() {
        if (this.markers.length != 0) {
          lines.forEach((x) => x.setMap(null));
          while (lines.length) {
            lines.pop();
          }
          for (const i in this.markers) {
            this.markers[i].setMap(null);
          }
          this.markers = [];
        }
      },
      reversePath(routeId, index) {
        let clickedRoute = routes.find((route) => route.Id === routeId);

        clickedRoute.DepOrder = clickedRoute.DepOrder === 'Dep' ? 'DepBack' : 'Dep';
        clickedRoute.Stops = routesJson[routeId].stopsId[clickedRoute.DepOrder];
        //
        // let stopIndex = this.stops.findIndex((x) => x.ShowDepartures === true);
        // this.stops.forEach((x) => x.ShowDepartures = false);

        this.drawMap(clickedRoute.Stops, index);
      },
      clickable(stop) {
        // stop.ShowDepartures = !stop.ShowDepartures;
        const name = `"${stop.Name}"`;
        document.querySelector(`[title=${name}]`)
          .click();
      },
      searchClosestHour(departuresArray){
        var now = new Date();
        let actualHour = now.getHours();
        let actualMinutes = now.getMinutes();

        let closest=departuresArray[0];

        for(let i=0; i < departuresArray.length; i++){
          let values = departuresArray[i].split(':');
          let hour = parseInt(values[0]);
          let minutes = parseInt(values[1]);
          if(hour >= actualHour){
            if(hour === actualHour){
              if(minutes > actualMinutes){
                minutes = minutes < 10 ? "0" + minutes : minutes;
                closest=hour + ":" + minutes;
                break;
              }
            }
            else {
              minutes = minutes < 10 ? "0" + minutes : minutes;
              closest=hour + ":" + minutes;
              break;
            }
          }
        }
        return closest;
      }
    },

  });

  function getWindowSize() {
    const windowWidth = window.innerWidth;
    windowWidth < 1024 ? isTablet = true : isTablet = false;

    return !(windowWidth < 1024);
  }
}());
